import axios from "axios";
import {API_GENIAM} from "../config/apiUrl";


export default async function (card, num) {
  try {
    const {data} = await axios.post(`${API_GENIAM}/v1/cart/purchase`, {
      address: null,
      cart: [{
        num: num,
        product_id: "GNM-001-0001-a2"
      }],
      source: card,
      totalByProductId: {"GNM-001-0001-a2": priceIncludeTax(num * 100)},
      comment: "",
      coupons: [],
      packages: [],
      receipt: false,
      seichokuByProductId: {},
      selected_time: "",
      type: "normal"
    })

    return data
  } catch (e) {
    console.log(e);
    return null
  }
}

const priceIncludeTax = (price) => {
  return price * 1.1
}

