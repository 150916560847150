import React, {useEffect, useState} from 'react';
import {useGlobal} from "reactn"
import {Grid, IconButton, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {ReactComponent as SearchSVG} from "../asSvg/icons/searchBGPrimary.svg";
import {iconNameList} from "../../common/muiIcons";

function ListIconSearch({icon, setIcon}) {
  const classes = useStyles()
  const [search, setSearch] = useState('')
  const [list, setList] = useState([])
  const [tasks] = useGlobal('tasks')

  useEffect(() => {
    getIconList()
    // eslint-disable-next-line
  }, [tasks, search])

  const handleSelectIcon = (name) => {
    setIcon(name)
  }

  const getIconList = () => {
    if (search) {
      setList(iconNameList.filter(item => item.includes(search) && !tasks?.data.map(task => task.icon).includes(item)));
    } else {
      setList(iconNameList.filter(item => !tasks?.data.map(task => task.icon).includes(item)))
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.boxSearch}>
        <TextField
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder="Search icon"
          variant="outlined"
          className={classes.searchInput}
          InputProps={{
            endAdornment: <IconButton
              className={classes.whiteText}

            >
              <SearchSVG className={classes.iconSVG}/>
            </IconButton>,
          }}
        />
      </div>
      <Grid container className={classes.container}>
        {
          list.map(item => {
            return (
              <Grid xs={2}
                    className={item === icon ? classes.selectItem : classes.item}
                    onClick={() => handleSelectIcon(item)}
              >
                <span className={"material-icons md-48"}>{item}</span>
                <span className={classes.nameIcon}>{item}</span>
              </Grid>
            )
          })
        }
      </Grid>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  item: {
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    flexDirection: "column",
    alignItems: "center"
  },
  container: {
    width: "100%",
    height: "calc(100vh - 400px)",
    minHeight: 300,
    overflow: "auto"
  },
  selectItem: {
    color: '#1790FF',
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    flexDirection: "column",
    alignItems: "center"
  },
  root: {
    marginTop: 10,
  },
  nameIcon: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "80%",
    fontSize: 12,
    marginBottom: 10,
  },
  iconSVG: {
    fill: '#1790FF '
  },
  whiteText: {
    fill: '#1790FF ',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0
  },
  searchInput: {
    width: "100%",
    margin: 'auto',
    position: 'relative',
    "& .Mui-focused": {
      border: `1px solid #1790FF`,
    },
    "& .MuiFormControl-root": {
      background: "#fff",
      borderRadius: "25px"
    },
    "& .MuiOutlinedInput-root": {
      padding: 0,
      background: "#FFF",
      borderRadius: "24px",
      height: "32px",
    },
    "& .MuiSvgIcon-root": {
      display: "none"
    },
    "& input": {
      fontSize: 14,
      fontFamily: 'Montserrat'
    },
    "& .MuiAutocomplete-input:first-child": {
      paddingLeft: 15
    },
    "& .MuiOutlinedInput-root fieldset": {
      border: '#1790FF',
    },
  },
  boxSearch: {
    backgroundColor: "#F1F1F1",
    borderRadius: "50px",
    padding: "5px",
    marginBottom: 10,
  }

}))

export default React.memo(ListIconSearch)
