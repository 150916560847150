import React, {useEffect, useRef, useState} from 'react';
import {useGlobal} from 'reactn';
import {isMobileOnly} from 'react-device-detect';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import './calendarCore.css';
// import './calendarList.css'
import './calendarGrid.css';
import {makeStyles} from '@material-ui/core/styles';
import _ from 'lodash';
import {dayHeaderContents} from './columnHead';
import {getCalendarEvents, getEventsInfo} from './Actions';
import store from 'store';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from '@material-ui/icons/Settings';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  text: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  calendar: {
    marginTop: 10,
  },
  events: {
    textAlign: 'center',
    height: '100%',
    minHeight: '1em',
    position: 'relative',
    textAlight: 'center',
  },
  checkbox: {
    top: '50%',
    position: 'absolute',
    left: '50%',
    margin: 0,
    padding: 0,
    transform: 'translate(-50%, -50%)',
    marginRight: '-50%',
    color: 'red',
  },
  headerHtml: {
    padding: 3,
    fontSize: '1em',
    [theme.breakpoints.down('md')]: {
      fontSize: '7px',
    },
  },
  headerHtmlDay: {
    padding: 5,
    fontSize: '1rem',
  },
  headerHtmlSmallDay: {
    fontSize: '0.8rem',
    padding: 2,
  },
  headerHr: {
    height: 1,
    width: '100%',
    backgroundColor: '#ccc',
    position: 'absolute',
    left: -1,
    [theme.breakpoints.down('md')]: {
      width: '110%',
    },
  },
  headerHtmlSmall: {
    fontSize: '1rem !important',
  },
  button: {
    background: "#8CC63F",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#81bd31",
    }
  }
}));

function CalendarComponent(props) {
  const classes = useStyles();
  const [date] = useGlobal('date');
  const [user] = useGlobal('user');
  const [myPaid] = useGlobal('myPaid');
  const [googleCalendars] = useGlobal('googleCalendars');
  const [schedules] = useGlobal('filterSchedules')
  const [dateList] = useGlobal('dateList')
  const [events, setEvents] = useState([]);
  const [open, setOpen] = useState(false);
  const locale = store.get('locale') || 'ja';
  const ref = useRef();

  useEffect(() => {
    if (ref && ref.current) {
      let calendarApi = ref.current.getApi();
      calendarApi.gotoDate(date);
    }
    // eslint-disable-next-line
  }, [date]);

  useEffect(() => {
    onFresh();
    // eslint-disable-next-line
  }, [googleCalendars, date, myPaid, schedules]);

  const onFresh = async () => {
    let range = isMobileOnly ? 7 : 14;
    let startTime = moment(date).startOf('week').utc().format();
    let endTime = moment(startTime).add(range, 'day').utc().format();

    const ggSchedules = filterDate(await getEventsInfo(
      moment(startTime).subtract('day').utc().format(),
      moment(endTime).endOf('day').utc().format(),
    ))

    const geSchedules = filterDate(await getCalendarEvents(
      moment(startTime).subtract(1, 'd').format('YYYY-MM-DD'),
      moment(endTime).add(1, 'd').format('YYYY-MM-DD'),
    ))

    setEvents(_.concat(ggSchedules, geSchedules, myPaid, schedules.map(s => ({
      ...s,
      resourceId: "b",
      backgroundColor: "red"
    }))));
  };
  const filterDate = (events) => {
    const selectDates = _.filter(dateList, d => d.checked)
    if (!selectDates?.length)
      return []
    return _.filter(events, e => {
      return _.find(selectDates, d => d.date === moment(e.start).format('YYYY-MM-DD') || d.date === moment(e.end).format('YYYY-MM-DD'))
    })
  }
  useEffect(() => {
    if (myPaid.length !== 0) {
      let newEvents = _.uniqBy(_.concat(events, myPaid), 'uuid');
      setEvents(newEvents);
    }
    // eslint-disable-next-line
  }, [myPaid]);

  const editCalendar = () => {
    window.open(process.env.REACT_APP_CALENDAR)

  }

  return (
    <div style={{ padding: '20px' }}>
      {/*<Typography style={{textAlign: 'center', fontSize: '20px'}}*/}
      {/*            onClick={() => setOpen(!open)}*/}
      {/*            className={classes.text}*/}
      {/*> My Calendar</Typography>*/}
      <div style={{ textAlign: "center" }}>
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => setOpen(!open)}
          endIcon={open ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
        >
          My Calendar
        </Button>
        <Tooltip title="Edit Calendar">
          <IconButton
            onClick={editCalendar}
          >
            <SettingsIcon/>
          </IconButton>
        </Tooltip>
      </div>
      <div className={classes.calendar}>
        <Collapse timeout="auto" unmountOnExit in={open}>

          <FullCalendar
            ref={ref}
            plugins={[resourceTimeGridPlugin]}
            height={'auto'}
            contentHeight={'auto'}
            locale={locale}
            headerToolbar={false}
            minTime={user.geniamUser?.times?.minTime?.label || '00:00'}
            maxTime={user.geniamUser?.times?.maxTime?.label || '24:00'}
            timeZone={'local'}
            titleFormat={{
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
            }}
            schedulerLicenseKey={'0151801436-fcs-1594028792'}
            weekNumbers={false}
            initialView={isMobileOnly ? 'CustomWeek' : 'custom2Week'}
            datesAboveResources={true}
            resources={[
              { id: 'a', title: '' },
              { id: 'b', title: '' }
            ]}
            events={events}
            views={
              {
                custom2Week: {
                  type: 'resourceTimeGrid',
                  duration: { weeks: 2 },
                },
                CustomWeek: {
                  type: 'resourceTimeGrid',
                  duration: { weeks: 1 },
                },
              }
            }
            slotEventOverlap={false}
            columnHeaderFormat={{
              day: '2-digit',
              weekday: 'short',
              meridiem: false,
              omitCommas: false,
            }}
            dayHeaderContent={dayHeaderContents}
            progressiveEventRendering={true}
            nowIndicator={true}
            selectMirror={true}
            displayEventTime={true}
            allDayText={''}
            allDaySlot={true}
            eventTextColor={'white'}
            eventResizableFromStart={false}
            // firstDay={1}

            // eventRender={(info) => {
            //   if (info.event.extendedProps.source ||
            //     info.event.extendedProps.googleEvent)
            //     info.el.style.border = 'none';
            //   else
            //     info.el.style.background = '#ff4500';
            //   eventRender(info, classes);
            // }}

          />
        </Collapse>
      </div>
    </div>
  );
}

export default CalendarComponent;
