import {getGlobal} from 'reactn';
import {ggTokenChecked} from '../../common/ggToken';
import axios from 'axios';
import _ from 'lodash';
import {API_PREFIX} from '../../config/apiUrl';

export const getCalendarEvents = async (start, end) => {
  let events = [];
  const {user} = getGlobal();
  if (user.user_id) {
    try {
      const {data} = await axios.get(
        API_PREFIX + `/calendar1/eventsCalendar?timeMin=${start}&timeMax=${end}`);
      data.forEach(event => {
        events.push({
          ...event,
          editable: false,
          source: 'calendar',
          // backgroundColor: 'rgba(87, 170, 221, 0.6)',
          title: `${event.title} ${event.projectName ?
            `(${event.projectName})` :
            ''}`,
        });
      });
    } catch (e) {

    }
  }
  return events.map(e => ({...e, resourceId: "a"}))
};

export const getEventsInfo = async (start, end) => {
  const {googleCalendarInit, googleCalendars} = getGlobal();
  let result = [];
  if (!googleCalendarInit || !googleCalendars?.length)
    return [];

  try {
    const checked = await ggTokenChecked();
    if (!checked)
      return result;

    const batch = window.gapi.client.newBatch();

    googleCalendars.forEach(group => {
      const request = window.gapi.client.calendar.events.list({
        'calendarId': group.id,
        'singleEvents': true,
        'timeMin': start,
        'timeMax': end,
        'maxResults': 2500,
        // "alwaysIncludeEmail":true
      });
      batch.add(request);
    });

    const data = await batch.then();
    if (data.result) {
      Object.keys(data.result).forEach((item) => {
        let calendarItems = data.result[`${item}`].result.items;
        if (calendarItems && calendarItems.length !== 0) {
          result = _.concat(result, formatGoogleEventsData(calendarItems));
        }
      });
    }
  } catch (e) {
    console.log(e);
  }
  return result.map(e => ({...e, resourceId: "a"}))
};

const formatGoogleEventsData = events => {
  return events.map(evt => ({
    title: evt.summary || '( no title )',
    editable: false,
    allDay: Boolean(evt.start.date),
    start: evt.start.date ? evt.start.date : evt.start.dateTime,
    end: evt.end.date ? evt.end.date : evt.end.dateTime,
    googleEvent: true,
    // backgroundColor: 'rgba(87, 170, 221, 0.6)',
  }));
};
