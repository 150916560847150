import React from 'react';
import {IconImage} from "./TaskCustom";
import {getIcon} from "./getIcon";
import {ReactComponent as AddLargeSVG} from "../asSvg/icons/addLarge.svg";
import {makeStyles} from "@material-ui/core/styles";
import styled from "styled-components";
import {Button} from "antd";


const IconButton = styled(Button)`
  margin-right: 5px;
  margin-bottom: 5px;
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  box-shadow: none !important;

  & span {
    font-size: 30px;
  }

  &:hover {
    background-color: #f1f1f1;
    color: unset
  }

  &:focus {
    background-color: #f1f1f1;
    color: unset
  }
`

const IconCustom = styled.span`
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
    // color: ${props => props.color ? props.color : ''}

`

function IconButtonCustom({
                            room,
                            idLive,
                            setOpenAddTask,
                            index,
                            callback = null,
                            field
                          }) {
  const classes = useStyles();

  return (
    <div>
      {
        room?.taskIcon?.icon ?
          <div className={classes.iconTask}>
            <IconButton
              disableRipple={true}
              onClick={() => setOpenAddTask({...room, idLive, callback, field})}
              key={index}
              id={'tool-tip-task' + room.taskIcon.icon + index}
              shape="circle"
              icon={room?.taskIcon?.type === "file" ?
                <IconImage src={room.taskIcon.file.url || ''}
                           color={"black"}/> :
                <IconCustom
                  color={''}
                >
                  {getIcon(room?.taskIcon?.icon)}
                </IconCustom>}
            >
            </IconButton>
          </div>
          :
          <IconButton
            disableRipple={true}
            className={classes.addIconSVGTask}
            onClick={() => setOpenAddTask({...room, idLive, callback, field})}
          >
            <AddLargeSVG/>
          </IconButton>
      }
    </div>
  );
}


const useStyles = makeStyles(theme => ({
  iconTask: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    //
    // position: 'absolute',
    // top: '50%',
    // right: 10,
    // transform: ' translateY(-65%)'
  },
  addIconSVGTask: {
    padding: 0,
    width: 28,
    height: 28,
    marginTop: 2,
    background: 'transparent !important',
    border: '1px solid #F1F1F1',
    "& svg": {
      fill: theme.props.primaryColor,
    }
  }
}));

export default IconButtonCustom;
