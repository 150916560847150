import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {ReactComponent as EditSVG} from '../asSvg/edit.svg';
import {Tooltip} from 'antd';
import {isEmpty, trim} from 'lodash';
import {useEnter} from "../../common/useHotkey";
import {useGlobal} from "reactn";

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: "center",
    fontSize: '1rem',
    fontWeight: "bold",
    color: theme.props.primaryColor,
    marginRight: 30,
    // textTransform: "uppercase",
    display: "inline-block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      marginRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    position: 'relative',
    '& svg': {
      display: 'none',
    },
    '&:hover': {
      '& svg': {
        display: 'block',
      },
    }
  },

  textField: {
    width: "100%",
    borderBottom: `1px solid ${theme.props.primaryColor}`,
    textAlign: "center",
    maxWidth: 400,

    "& .MuiOutlinedInput-root": {
      // border: `1px solid ${theme.props.primaryColor}`,
      border: "none",
      borderRadius: '24px',
      textAlign: "center",
      fontSize: '1rem',
      fontWeight: "bold",
      color: theme.props.primaryColor,
      [theme.breakpoints.down('md')]: {
        fontSize: 14,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
    "& .MuiOutlinedInput-root fieldset": {
      border: theme.props.primaryColor,
    },
    "& .MuiOutlinedInput-input": {
      padding: 5,
      // fontSize: 18,
    }
  },
  root: {
    textAlign: "left",
    width: "100%",
    paddingRight: 20,
  },
  IconEdit: {
    position: 'absolute',
    top: 4,
    bottom: 0,
    right: -30,
    fill: theme.props.primaryColor
  }
}))

function EventCalendarTitle({room, name, onDone, field}) {
  const classes = useStyles()
  const [user] = useGlobal('user')
  const [colors] = useGlobal('colors')
  const [editing, setEditing] = useState(false)
  const [title, setTitle] = useState("")

  useEffect(() => {
    setTitle(name)
    // eslint-disable-next-line
  }, [name])

  const onChangeTitle = (e) => {
    setTitle(e.target.value)
  }

  const [isTitleInputEnter] = useEnter('#title-input')

  useEffect(() => {
    if (isTitleInputEnter) onSave()
  }, [isTitleInputEnter])

  const onSave = async () => {
    if (isEmpty(trim(title))) {
      setTitle(name)
      setEditing(false)
      return;
    }
    if (title === name) {
      setEditing(false)
      return;
    }
    try {
      await onDone(title, field);
    } catch (e) {
      console.log(e);
    } finally {
      setEditing(false)
    }
  }

  const onSetEditing = () => {
    if (room?.creator?.userId === user?.user_id) {
      setEditing(true)
    }
  }

  return (
    <div className={classes.root}>
      {
        editing && room?.creator?.userId === user?.user_id ?
          <TextField value={title}
                     variant="outlined"
                     id={'title-input'}
                     className={classes.textField}
                     onChange={(e) => onChangeTitle(e)}
                     onBlur={onSave}
                     autoFocus={true}
          />
          :
          room?.creator?.userId !== user?.user_id ?
            <div className={classes.title}>
              {name}
            </div>
            :
            <Tooltip
              placement="right"
              title={"Click to edit"}
              color={colors?.primary}
              destroyTooltipOnHide={{keepParent: false}}
            >
              <div className={classes.title} onClick={onSetEditing}>
                {name}
                <EditSVG className={classes.IconEdit}/>
              </div>
            </Tooltip>
      }
    </div>
  );
}

export default EventCalendarTitle;
