import axios from "axios";
import {API_PREFIX} from "./apiUrl";
import {ACCESS_TOKEN_KEY, GENIAM_REFRESH_TOKEN} from "./constant";
import {refreshToken} from "../components/actions/user";
import {removeCookie} from "../common/cookies";

axios.defaults.baseURL = API_PREFIX

export default () => {
  //REQUEST
  axios.interceptors.request.use(
    (config) => {
      console.log(`---- ${config.method.toUpperCase()} ${config.url} ----`);
      const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      return config;
    },
    error => {
      if (error && error.request) {
        console.log(error);
        console.log(error.request);
      }
      return Promise.reject(error);
    });

  // RESPONSE
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      if (!error.response)
        return Promise.reject(error)
      const originalRequest = error.config;
      if (
        (error.response.status === 400 && error.response.data.error_code === 'NotAuthorizedException') ||
        (error.response.status === 401 && error.response.data.error === "login required") ||
        (error.response.status === 401 && error.response.data.error_message === "no authenticated")
      ) {
        if (error.response.data.error_message === 'Invalid Refresh Token' || error.response.data.error_message === 'Refresh Token has been revoked') {
          removeCookie(GENIAM_REFRESH_TOKEN)
          // window.location.assign(LOGIN_URI + '?redirect_url=' + window.location.href + '?cookies')
          return Promise.reject(error)
        }
        const data = await refreshToken()
        console.log(data)
        if (data) {
          originalRequest.headers['Authorization'] = `Bearer ` + data.accessToken;
          return axios(originalRequest)
        } else {
          return Promise.reject(error);
        }
      }
      return Promise.reject(error);
    }
  )
  ;
}
