import React from 'react';
import styled from 'styled-components';
import {Button} from "antd";
import TooltipCustom from "./TooltipCustom";

const DivList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 5px;
`;

const IconButton = styled(Button)`
  margin-right: 5px;
  margin-bottom: 5px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  box-shadow: none;

  &:hover {
    background-color: #f1f1f1;
    color: unset
  }

  &:focus {
    background-color: #f1f1f1;
    color: unset
  }
`

export const IconImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 3px solid ${props => props.color};
`
const IconCustom = styled.span`
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
  color: ${props => props.color ? props.color : ''}

`

const oldIcons = {
  "AccountCircle": "account_circle",
  "Group": "group",
  "AssignmentInd": "assignment_ind",
  "AssignmentTurnedIn": "assignment_turned_in"
}

const getIcon = (name) => {
  return oldIcons[name] || name
}

function TaskCustom({
                      children,
                      items,
                      callBack,
                      disabled,
                      color,
                      event,
                      isGetPopup = false
                    }) {

  const onClick = (item) => {
    if (callBack && typeof callBack === 'function')
      return callBack(item)
  }

  return (
    <DivList>
      {
        items.map((item, index) => {
          if (!item)
            return null;
          return (
            <TooltipCustom
              key={item.name + index}
              title={item.name}
              placement={'bottom'}
              overlayClassName={'hover-show-tooltip hover-show-tooltip-2'}
              getId={isGetPopup ? 'tool-tip-task' + item.name + index : null}
              destroyTooltipOnHide={{keepParent: false}}
            >
              <IconButton

                key={index}
                id={'tool-tip-task' + item.name + index}
                shape="circle"
                onClick={() => onClick(item)}
                disabled={disabled}
                icon={item.type === 'file' ? <IconImage src={item.file.url}
                                                        color={event?.task === item.file.id ? color : "#f1f1f1"}/> :
                  <IconCustom
                    color={event?.task === item.icon ? color : ''}
                  >
                    {getIcon(item.icon)}
                  </IconCustom>}
              >

              </IconButton>
            </TooltipCustom>
          )
        })
      }
      {children}
    </DivList>
  );
}

export default TaskCustom;
