import {getGlobal} from 'reactn'
import {findIndex} from 'lodash'
import {iconNameList} from "../../common/muiIcons";


const oldIcons = {
  "AccountCircle": "account_circle",
  "Group": "group",
  "AssignmentInd": "assignment_ind",
  "AssignmentTurnedIn": "assignment_turned_in"
}

export const getIcon = (name) => {
  return oldIcons[name] || name
}

export const isMaterialIcon = name => {
  return name && iconNameList.includes(getIcon(name))
}

export const isImageTask = uid => {
  const {tasks} = getGlobal()
  return findIndex(tasks?.data || [], item => item?.type === "file" && item?.file?.id === uid) !== -1
}

export const getTaskUrl = uid => {
  const {tasks} = getGlobal()
  // console.log("Task:",tasks)
  let idx = findIndex(tasks?.data || [], item => item?.type === "file" && item?.file?.id === uid)
  if (idx === -1)
    return null
  return tasks.data[idx]?.file?.url || ''
}
