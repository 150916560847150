// import {getGlobal} from "reactn";


export const alertNotExists = (groupExists = "The stream no longer exists", isLogin = false) => {
  alert(groupExists)
  // const {listMeetings} = getGlobal();
  // if (listMeetings?.length && listMeetings[0]?.id)
  if (isLogin) {
    return window.location.assign(process.env.REACT_APP_MYACCOUNT + '/login?redirect_url=' + encodeURIComponent(window.location.href))
  }
  return window.location.assign(`/`)
  // else
  //     return window.history.replaceState({}, 'event', `/event`)

}

//
// export const alertNotSetting = (groupExists = "The schedule id no longer exists") => {
//     alert(groupExists)
//     const {listMeetings} = getGlobal();
//     if (listMeetings?.length && listMeetings[0]?.id)
//         return window.history.replaceState({}, 'event', `/event?id=${listMeetings[0].id}`)
//     else
//         return window.history.replaceState({}, 'event', `/event`)
//
// }
