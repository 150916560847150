import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import {makeStyles} from "@material-ui/core/styles";

function TabsCustom(props) {
  const {value, onChange, children} = props
  const classes = useStyles()
  const classProps = props.className || ''
  return (
    <Tabs
      {...props}
      value={value}
      onChange={onChange}
      className={`${classes.tabs} ${classProps}`}
    >
      {children}
    </Tabs>
  );
}

export default TabsCustom;
const useStyles = makeStyles(theme => ({
  tabs: {
    backgroundColor: "#fff",
    minWidth: "unset",
    minHeight: "unset",
    maxWidth: "unset",
    maxHeight: "unset",
    borderRadius: "25px",
    alignItems: "center",
    width: '95%',
    margin: 'auto',
    "& .MuiTab-root": {
      minWidth: "unset",
      minHeight: "unset",
      maxWidth: "unset",
      maxHeight: "unset",
      borderRadius: "25px",
      color: "#000",
      fontSize: 14,
      fontFamily: "Montserrat, sans-serif !important",
    },
    "& .MuiTabs-indicator": {
      display: "none"
    },
    "& .Mui-selected": {
      background: '#1790FF',
      color: "#fff",
      fontWeight: "bold"
    }
  },
  tab: {
    border: "none",
    color: "#000"
  }
}))
