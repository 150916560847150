import {db} from "../firebaseConfig";
import {
  CALENDAR_COLLECTION,
  STREAM_COLLECTION,
  USER_COLLECTION
} from "../config/constant";


export const getUsersCollRef = () => {
  return db.collection(`${USER_COLLECTION}`)
}
export const getStreamCollRef = () => {
  return db.collection(`${STREAM_COLLECTION}`)
}

export const getUserDocRef = (userId) => {
  return db.doc(`${USER_COLLECTION}/${userId}`)
}

export const getStreamDocRef = (streamId) => {
  return db.doc(`${STREAM_COLLECTION}/${streamId}`)
}

export const getRoomsCollRef = (streamId) => {
  return db.collection(`${STREAM_COLLECTION}/${streamId}/rooms`)
}

export const getRoomsDocRef = (streamId, roomId) => {
  return db.doc(`${STREAM_COLLECTION}/${streamId}/rooms/${roomId}`)
}

export const getCalendarDocRef = (userId) => {
  return db.doc(`${CALENDAR_COLLECTION}/${userId}`)
}
