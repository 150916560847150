import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {get, isEmpty} from 'lodash'
import {Box, Grid, Tab, Tabs} from '@material-ui/core'
import {giftData} from "./data";
import SendGiftConfirm from "./SendGiftConfirm";
import axios from "axios";
import {API_GENIAM} from "../../../config/apiUrl";
import {setGlobal} from 'reactn'
import {getCoins} from "../../../common/getCoins";
import {toast} from "react-toastify";

function Gift({data}) {
  const classes = useStyles()
  const [tab, setTab] = useState(1)
  const [gift, setGift] = useState(null)
  const [sendGift, setSendGift] = useState(null)
  console.log({data});
  const handleSendGif = async (gift) => {
    try {
      if (!data?.uid)
        return;
      const res = await axios.post(`${API_GENIAM}/questum/sendGift`, {
        gift,
        metamorId: data.uid
      })
      setGlobal({
        myTickets: res.data.newCoins
      })
      toast.success('sent', {
        autoClose: 1000
      })
    } catch (e) {
      const message = get(e, "response.data.error") || "send fail. try again late"
      toast.error(message, {
        autoClose: 1000
      })
      getCoins()
    }
  }
  if (isEmpty(data))
    return null;
  return (
    <div className={classes.root}>
      <Box sx={{width: "100%"}}>
        <Tabs
          value={tab}
          onChange={(e, value) => {
            console.log(value);
            setTab(value)
          }}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab value={1} label="Gift pack 1"/>
          <Tab value={2} label="Gift pack 2"/>
        </Tabs>
        {
          tab === 1 ?
            <div className={classes.content}>
              <Grid container spacing={2}>
                {
                  giftData.map(item => (
                    <Grid item key={item.id} xs={12} sm={3}
                          className={item.id === gift?.id ? classes.select : classes.unSelect}>
                      <img alt={'gift'} src={item.src} className={classes.img}
                           onClick={(e) => {
                             setGift(item)
                             setSendGift(e.currentTarget)

                           }}/>
                      <div className={classes.coin}>
                        <img alt={'price'} src={'/image/coin.svg'}/>
                        <span>{item.price}</span>
                      </div>
                    </Grid>
                  ))
                }
              </Grid>
            </div>
            :
            null
        }
        <SendGiftConfirm gift={gift} target={sendGift} setTarget={setSendGift}
                         onSend={handleSendGif}/>
      </Box>
    </div>

  );
}


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 10,
    backgroundColor: "#fff",
    borderRadius: 5,
    padding: 5,
  },
  content: {
    marginTop: "20px",
    backgroundColor: "#f1f1f1",
    borderRadius: 5,
    padding: 5
  },
  img: {
    width: "100%",
    margin: "auto",
    cursor: "pointer"
  },
  coin: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    marginTop: 5
  },
  unSelect: {
    background: "#f1f1f1",
  },
  select: {
    background: "#fff",
    borderRadius: 5,
    border: "1px solid #1790FF"
  }
}))

export default Gift;
