import React from 'react';
import {makeStyles} from "@material-ui/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.props.primaryColor,
    margin: 'auto',
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: `${theme.props.primaryColor === '#1790FF' ? '#FAAD14' : '#1790FF'}`,
    },
    "& *": {
      animationDuration: '2s !important'
    }
  }
}))

function ClfLinearProgress(props) {
  const classes = useStyles()
  return (
    <LinearProgress className={classes.root}/>
  );
}

export default ClfLinearProgress;
