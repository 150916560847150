import React from 'react';
import {
  Button,
  Divider,
  makeStyles,
  Popover,
  Typography
} from "@material-ui/core";

function SendGiftConfirm({gift, target, setTarget, onSend}) {
  const classes = useStyles()

  if (!gift || !target)
    return null;
  return (
    <Popover
      open={Boolean(target)}
      anchorEl={target}
      onClose={() => setTarget(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div className={classes.root}>
        <Typography>Would you like to send this gift?</Typography>
        <Divider/>
        <div className={classes.actions}>
          <Button onClick={() => setTarget(null)}
                  variant={"outlined"}
                  color={"secondary"}
                  className={classes.button}
          >
            Cancel
          </Button>
          <Button
            className={classes.button}
            variant={"outlined"}
            color={"primary"}
            onClick={() => {
              onSend(gift)
              setTarget(null)
            }}
          >send gift</Button>
        </div>
      </div>
    </Popover>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: 20
  },
  button: {
    fontWeight: 700,
    marginLeft: 10
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10
  }
}))

export default SendGiftConfirm;
