import {refreshToken} from "../components/actions/user";
import {auth} from "../firebaseConfig";

export const signInFirebase = async () => {
  try {
    const data = await refreshToken()
    await auth.signInWithCustomToken(data.firebaseToken)
  } catch (e) {
    console.log(e);
  }
}

