import React, {useEffect, useState} from 'react';
import getPaymentList from "../../common/getPaymentList";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Radio,
  Typography
} from '@material-ui/core'
import {makeStyles} from "@material-ui/core/styles";
import currencyFormat from "../common/currencyFormat";
import purchaseCoins from "../../common/purchaseCoins";
import {toast} from "react-toastify";
import {getCoins} from "../../common/getCoins";
import {setGlobal} from 'reactn'

function PaymentList({open, setOpen, numOfTickets}) {

  const classes = useStyles()
  const [list, setList] = useState([])
  const [selected, setSelected] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getPaymentList().then(data => {
      setList(data)
      if (data?.length)
        setSelected(data[0].id)
    })
  }, [])
  const handlePurchase = async () => {
    if (!selected)
      return;
    try {
      setLoading(true)
      const data = await purchaseCoins(selected, numOfTickets)
      if (!data)
        return toast.error('payment fail')
      await getCoins()
      toast.success("success")

    } catch (e) {

    } finally {
      setGlobal({
        showCart: false
      })

      setLoading(false)
      setOpen(false)

    }

  }
  return (
    <Dialog open={open}
            onClose={() => setOpen(false)}
            maxWidth={'md'}
            fullWidth={true}
    >
      <DialogTitle>Select method payment</DialogTitle>
      <DialogContent>
        <div>
          Purcharse {Number(numOfTickets)} coins
          : {currencyFormat.format(+numOfTickets * 100)}（税別）
        </div>
        <Grid container>
          {
            list.map((item, index) => {
              return (
                <Grid item xs={12} md={4} key={index}>
                  <Card>
                    <CardHeader
                      action={
                        <Radio
                          checked={item.id === selected}
                          onClick={() => setSelected(item.id)}
                          disabled={loading}
                        />
                      }
                      title={`Method of payment ${index + 1}`}
                    />
                    <CardContent>
                      <div className={classes.brand}>
                        <img src={"/image/cards/" + item.brand + ".png"}
                             height={24} alt=''/>
                        <Typography
                          className={classes.bold}>{item.brand}</Typography>
                      </div>
                      <Typography>Four orders of magnitude <span
                        className={classes.bold}>{item.last4}</span></Typography>
                      <Typography>Date of expiry <span
                        className={classes.bold}>{item.exp_month}/{item.exp_year} </span></Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )
            })
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(false)}
        >Cancel</Button>
        <Button
          disabled={loading}
          onClick={handlePurchase}
        >Yes , Buy</Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 700
  },
  brand: {
    display: "flex", alignItems: "center"
  }
}))

export default PaymentList;
