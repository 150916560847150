import React, {useEffect, useState} from 'react';
import {useGlobal} from 'reactn';
import {makeStyles} from "@material-ui/styles";
import ListIconSearch from "./ListIconSearch";
import Tab from "@material-ui/core/Tab";
import {CLFButtonSVG} from "react-antd-button-svg-icons";
import {Button, Upload,} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import {toast} from "react-toastify";
import DialogCustom from "../Custom/DialogCustom";
import TabsCustom from "../Custom/TabsCustom";
import {storage} from "../../firebaseConfig";

function SelectIconsDialog({onAdd, loading}) {
  const classes = useStyles()
  const [name, setName] = useState('')
  const [icon, setIcon] = useState('')
  const [tab, setTab] = useState(0)
  const [openAddTask, setOpenAddTask] = useGlobal('openAddTask')
  const [user] = useGlobal('user')
  const [file, setFile] = useState(null)
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (!Boolean(openAddTask?.taskIcon))
      return null;

    setName(openAddTask.taskIcon.name)
    setIcon(openAddTask.taskIcon.icon)
    if (openAddTask.taskIcon.type === "file") {
      setTab(1)
      setFile(openAddTask.taskIcon.file || null)
    }
  }, [openAddTask])

  const handleClose = () => {
    setOpenAddTask(null)
  }

  const handleChangeTab = (event, newValue) => {
    setTab(newValue)
  }

  const handleUpload = async file => {
    try {

      if (!user.user_id)
        return
      if (!file.type || !file.type?.includes("image/"))
        return toast.error("not image file")

      setUploading(true)
      const fileName = `${new Date().getTime()}_${file.name}`
      const ref = storage.ref().child(`images/${user.user_id}/tasks/${fileName}`)
      const snap = await ref.put(file)
      const url = await snap.ref.getDownloadURL()
      setFile({
        id: file.uid,
        status: "done",
        url,
        name: fileName
      })

    } catch (e) {

    } finally {
      setUploading(false)
    }
  }

  const fileList = file ? [file] : []

  return (
    <DialogCustom
      open={openAddTask}
      onClose={handleClose}
      title={openAddTask?.taskIcon?.name !== undefined ? "Update Task Icon" : "Add Task Icon"}
      fullWidth={true}
      maxWidth={'xl'}
      actions={<React.Fragment>
        <CLFButtonSVG
          name={'Cancel'}
          onClick={handleClose}
          minWidth={'100px'}
          className={classes.secondary}
        />
        <CLFButtonSVG
          minWidth={'100px'}
          name={'Ok'}
          onClick={() => {
            if (openAddTask?.callback && typeof openAddTask.callback === "function") {
              if (tab === 1)
                return openAddTask?.callback({
                  name: name || null,
                  type: "file",
                  file: file || null,
                  icon: file.id
                }, openAddTask.field)
              return openAddTask?.callback({
                name: name || null,
                icon,
                type: "icon"
              }, openAddTask.field)
            }

            if (onAdd && typeof onAdd === "function" && openAddTask?.taskIcon?.name === undefined) {
              if (tab === 1)
                return onAdd({name, type: "file", file})
              onAdd({name, icon, type: "icon"})
            } else {
              if (tab === 1)
                return onAdd({name, type: "file", file})
              onAdd({name, icon, type: "icon"})
            }
          }}
          loading={loading}
          disable={loading}
          className={classes.primary}
        />
      </React.Fragment>}
    >
      <TabsCustom value={tab}
                  onChange={handleChangeTab}
                  className={classes.tabsBox}
      >
        <Tab label={"Select Icon"} className={classes.tab}/>
        <Tab label={"Upload Image"} className={classes.tab}/>
      </TabsCustom>
      <div className={classes.root}>
        {
          tab === 0 ?
            <div>
              <div className={classes.fieldName}>
                <input type="text"
                       onChange={e => setName(e.target.value)}
                       value={name}
                       autoFocus={true}
                       placeholder={"Name"}
                />
              </div>
              <ListIconSearch icon={icon} setIcon={setIcon}/>
            </div>
            :
            <div>
              <div className={classes.uploadBox}>
                <Upload
                  name={"file"}
                  action={handleUpload}
                  listType="picture-card"
                  fileList={fileList}
                  maxCount={1}
                  onRemove={file => {
                    setFile(null)
                  }}
                  onChange={info => {
                    if (info.file.type || !info.file.type?.includes("image/")) {
                      info.fileList = []
                    }
                  }}
                >
                  {!file && !uploading ?
                    <Button icon={<UploadOutlined/>}>Click to Upload</Button>
                    : null}
                </Upload>
              </div>
              <div className={classes.fieldName}>
                <input type="text"
                       onChange={e => setName(e.target.value)}
                       value={name}
                       autoFocus={true}
                       placeholder={"Name"}
                />
              </div>
            </div>
        }

      </div>
    </DialogCustom>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: "10px 20px 20px 20px",
    minHeight: 455,
  },
  tabsBox: {
    margin: "0 20px",
    background: "#f1f1f1"
  },
  tab: {
    // background: "#fff",
    width: "50%"
  },
  fieldName: {
    backgroundColor: "#F1F1F1",
    borderRadius: "50px",
    padding: "5px",
    marginBottom: 10,
    "& input": {
      border: "none",
      outline: "none !important",
      background: "#fff",
      height: "32px",
      width: "100%",
      borderRadius: "50px",
      padding: "0 10px",
      color: "#000",
      fontSize: 14,
      fontFamily: 'Montserrat'
    }
  },
  primary: {
    background: `#1790FF !important`,
    borderColor: `#1790FF !important`
  },
  secondary: {
    background: `#690003 !important`,
    borderColor: `#690003 !important`
  },
  uploadBox: {
    width: 150,
    margin: "auto"
  }

}))
export default SelectIconsDialog;
