import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Col, Popconfirm, Row} from "antd";
import InputLinks from "./InputLinks";
import {useGlobal} from "reactn";
import TextFieldCustom from "./TextFieldCustom";
import {getRoomsDocRef} from "../../common/useRef";
import IconButtonCustom from "./IconCustom";
import {ReactComponent as DeleteGroupSVG} from "../asSvg/groupDelete.svg";

function ItemList({room, idLive, index}) {
  const classes = useStyles();
  const [, setOpenAddTask] = useGlobal('openAddTask')
  const [user] = useGlobal('user')

  const onDone = async (name, field) => {
    try {
      const roomRef = getRoomsDocRef(idLive, room.id)
      await roomRef.set({[field]: name}, {merge: true})
    } catch (e) {
      console.log(e);
    }
  }

  const onDelete = async () => {
    try {
      const roomRef = getRoomsDocRef(idLive, room.id)
      await roomRef.set({isDeleted: true}, {merge: true})
    } catch (e) {
      console.log(e.toString())
    }
  }

  const onCancel = () => {

  }

  return (
    <div className={classes.root}
         style={{pointerEvents: room?.creator?.userId !== user?.user_id ? "none" : "inherit"}}>
      {
        room?.creator?.userId === user.user_id &&
        <Popconfirm
          title={'Do you want to delete?'}
          onConfirm={onDelete}
          onCancel={onCancel}
          okText={'Yes'}
          cancelText={'No'}
          overlayClassName={classes.confirmStyle}
        >
          <DeleteGroupSVG
            id={'list-setting-identity-delete' + room.id}
            className={classes.iconAntdStyle}
          />
        </Popconfirm>
      }
      <Row gutter={16}>
        <Col xs={4} className={classes.iconLeft}>
          <IconButtonCustom
            room={room}
            idLive={idLive}
            setOpenAddTask={setOpenAddTask}
            index={index}
          />
        </Col>
        <Col xs={20} className={classes.roomInfo}>
          <TextFieldCustom name={room.title} room={room} onDone={onDone}
                           field={'title'}/>
          <TextFieldCustom name={room.description} room={room} onDone={onDone}
                           field={'description'}/>
          <InputLinks value={room.conferenceUrl} room={room}/>
          <div>By: {room.creator.email}</div>
        </Col>
      </Row>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    background: '#F1F1F1',
    borderRadius: 5,
    position: 'relative',
    marginBottom: 5
  },
  roomInfo: {
    padding: 5,
    fontSize: '0.9rem'
  },
  title: {
    fontWeight: "bold"
  },
  description: {},
  iconLeft: {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center'
  },
  renderItemActive: {
    width: '100%',
    margin: "10px 0",
    padding: 15,
    background: "#ffe7e7",
    alignItems: "center",
    display: "flex",
    cursor: "pointer",
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: 5,
      width: "auto"
    },
    "&:hover": {
      background: "#ffe7e7",
    },
    "& .MuiStepper-root": {
      background: "#ffe7e7",
    },
    "& .MuiTypography-body1": {
      fontSize: "1.5rem"
    },
    "& .MuiTypography-body2": {
      fontSize: "1rem"
    }
  },
  renderItem: {
    width: '100%',
    // margin: "10px 0",
    padding: 0,
    background: "#fff",
    alignItems: "center",
    display: "flex",
    cursor: "pointer",
    zIndex: 1,
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      padding: 5,
      width: "auto"
    },
    "&:hover": {
      background: "#ffe7e7",
      "& .MuiStepper-root": {
        background: "#ffe7e7",
      }
    },
    "& .MuiTypography-body1": {
      fontSize: "1.5rem"
    },
    "& .MuiTypography-body2": {
      fontSize: "1rem"
    },
    borderRadius: 9
  },
  date: {
    display: "block",
    fontSize: 64,
    lineHeight: 1,
    fontWeight: "bold",
    [theme.breakpoints.down('sm')]: {
      fontSize: "24px !important"
    }
  },
  timeBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: 15,
    borderRadius: 9,
    background: theme.props.primaryColor,
    color: "#fff",
    padding: 10,
    textTransform: "uppercase",
    minWidth: 130,
    [theme.breakpoints.down('xs')]: {
      padding: "10px 5px",
      marginRight: 8
    },
  },
  eventTime: {
    "& .MuiListItemText-primary": {
      textTransform: "uppercase",
      fontWeight: 700,
      fontSize: 24,
    },
    "& .MuiListItemText-secondary": {
      color: "#000",
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      "& .MuiListItemText-primary": {
        fontSize: 18
      },
      "& .MuiListItemText-secondary": {
        fontSize: 11,
      },
    },
  },
  divPosition: {
    position: 'relative'
  },
  iconTask: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    //
    // position: 'absolute',
    // top: '50%',
    // right: 10,
    // transform: ' translateY(-65%)'
  },
  addIconSVGTask: {
    padding: 10,
    width: 28,
    height: 28,
    marginTop: 2,
    background: 'transparent !important',
    border: '1px solid #F1F1F1',
    "& svg": {
      fill: theme.props.primaryColor,
    }
  },
  timeRound: {
    fontSize: 20
  },
  confirmStyle: {
    zIndex: '9999 !important'
  },
  iconAntdStyle: {
    position: "absolute",
    top: 2,
    right: 0,
    zIndex: 99,
    fontSize: '1.5rem',
    fill: theme.props.primaryColor,
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: 5
  },
}));

export default ItemList;
