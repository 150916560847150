import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import axiosConfig from './config/axios';
import './globalData';
import './firebaseConfig';

import GoogleAuth from './components/Calendar/google/auth';
import {ThemeProviderContext} from "./context/ThemeContext";

axiosConfig();
GoogleAuth();
ReactDOM.render(
  <ThemeProviderContext>
    <Router history={createBrowserHistory()}>
      <App/>
    </Router>
  </ThemeProviderContext>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
