import React, {useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import qs from "query-string";
import {getRoomsCollRef, getStreamDocRef} from "../common/useRef";
import {alertNotExists} from "./alertCustom";
import {findIndex, orderBy} from "lodash";
import axios from "axios";
import {API_PREFIX} from "../config/apiUrl";
import {getGlobal, useGlobal} from "reactn";

const StreamContext = React.createContext({});

export const useStreamContext = () => {
  return useContext(StreamContext);
}

export function StreamProviderContext({children}) {
  const meeting = useProviderStreaming();
  return <StreamContext.Provider
    value={meeting}>{children}</StreamContext.Provider>;
}

function useProviderStreaming() {
  const history = useHistory();
  const [user] = useGlobal('user')
  const [loading] = useGlobal('loading')
  const query = qs.parse(window.location.search)
  const [iFrame, setIFrame] = useState(null)
  // const [iFrameChat, setIFrameChat] = useState(null)
  const params = useParams() // no delete
  const [loadingIframe, setLoadingIframe] = useState(false)
  const [listRoom, setListRoom] = useState([])
  //
  // const iFrameTest = '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://vimeo.com/event/846093/embed/02e2070cad" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>'
  // const iFrameChatTest = '<iframe src="https://vimeo.com/event/1364446/chat/" width="100%" height="600px" frameborder="0"></iframe>'

  useEffect(() => {
    if (loading)
      return null;

    if (!loading && !user?.user_id)
      return alertNotExists('You must login!', true);

  }, [loading, user?.user_id])

  useEffect(() => {
    if (!user?.user_id)
      return null;

    console.log("chang streamID....", params)
    let subStream
    let subRoom
    if (!query?.id || !query?.target) {
      history.push(`/`)
    }
    if (query?.id && query?.target) {
      console.log('get db ', query.id)
      // if (query?.id === 'testIFrame') {
      //   setIFrame(iFrameTest)
      //   setIFrameChat(iFrameChatTest)
      // }

      setLoadingIframe(true)
      const streamRef = getStreamDocRef(query.id)
      const roomsRef = getRoomsCollRef(query.id)
      subStream = streamRef
        .onSnapshot(snapshot => {
          if (!snapshot.exists || snapshot.data().isDeleted) {
            return alertNotExists();
          }
          const streamData = {...snapshot.data(), id: snapshot?.id}
          checkPaymentCode(streamData).then(result => {
            console.log("result", result);
            if (result) {
              let index = findIndex(streamData.embed, {id: query.target})
              if (index !== -1) {
                setIFrame({...streamData, embed: streamData.embed[index]})
              } else {
                setLoadingIframe(false)
                return alertNotExists();
              }
              // setIFrameChat(streamData.iFrameChat)
              setLoadingIframe(false)
            } else {
              setLoadingIframe(false)
              alertNotExists('You need registered this product to watch this')
            }
          });
        })
      subRoom = roomsRef
        .where('idEmbed', '==', query.target)
        .where('isDeleted', '==', false)
        .onSnapshot(snapshots => {
          if (!snapshots.size)
            return null;

          const rooms = snapshots.docs.map(snap => ({
            ...snap.data(),
            id: snap.id
          }))

          setListRoom(orderBy(rooms, 'createdAt', 'desc'))
        })
    }

    return () => {
      if (subStream)
        subStream()
      if (subRoom)
        subRoom()
    }

    // eslint-disable-next-line
  }, [user?.user_id, query?.id, query?.target])

  const checkPaymentCode = async (streamData) => {
    const {user} = getGlobal();
    try {
      const res = await axios.post(`${API_PREFIX}/geniam/product/filterCustomEmail`, {
        isOnlyActive: true,
        products: [streamData.product || {}]
      })

      const emailList = res.data

      if (!emailList?.length)
        return false

      return emailList.includes(user.email)

    } catch (e) {
      console.log(e);
      return false
    }
  }

  return {iFrame, listRoom, loading: loadingIframe}
}
