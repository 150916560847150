import React, {useEffect} from 'react';
import {setGlobal, useGlobal} from 'reactn';
import './App.css';
import HomePage from './components/HomePage/HomePage';
import {Route, Switch, withRouter} from 'react-router-dom';
import MenuAppBar from './components/MenuAppBar/MenuAppBar';
import qs from 'query-string';
import {getUserData} from './components/actions/user';
import Cookies from 'js-cookie';
import {GENIAM_REFRESH_TOKEN} from './config/constant';
import {getSubscriptions} from './components/Cart/CartProductList';
import {OldBuyCourse} from './components/common/OldBuyCourse';
import MetamorItems from './components/metamors/MetamorItems';
import Cart from './components/Cart';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HistoryComponent from './components/HistorySubs';
import {ggTokenChecked} from './common/ggToken';
import _ from 'lodash';
import LiveStreaming from "./components/LiveStreaming";
import {StreamProviderContext} from "./context/StreamProviderContext";
import {auth} from "./firebaseConfig";
import {signInFirebase} from "./common/user";
import ClfLinearProgress from "./components/Custom/CLFLinearProgress";
import {getCoins} from "./common/getCoins";

// require('./components/Ws/connectWs');

function App({history}) {
  // const [coins, setCoins] = useState(0)
  // const [loading, setLoading] = useState(false)
  const [user] = useGlobal('user');
  const [loading] = useGlobal('loading');
  const [ggCalInit] = useGlobal('ggCalInit');
  const [googleCalendarInit] = useGlobal('googleCalendarInit');
  const path = window.location.pathname;
  const query = qs.parse(window.location.search);

  useEffect(() => {
    if (ggCalInit) {
      ggTokenChecked();
    }
  }, [user, ggCalInit]);

  useEffect(() => {
    if (googleCalendarInit) {
      window.gapi.client.calendar.calendarList.list().then(({result}) => {
        const data = result.items;
        _.remove(data, g => {
          return g.id.includes('#contacts@group') || g.accessRole ===
            'freeBusyReader' || g.id.includes('#holiday@group');
        });
        setGlobal({
          googleCalendars: data,
        }, () => {

        });
      }).catch(console.log);
    }
  }, [googleCalendarInit]);


  useEffect(() => {
    const {
      token,
      refreshToken,
      showCart,
      donePaid,
    } = qs.parse(window.location.search);
    if (donePaid) {
      toast.success(' Thanks To Purchase ');
    }
    if (showCart) {
      setGlobal({showCart: true});
    }
    if (token || refreshToken) {
      const opts = {expires: 1826};
      if (process.env.NODE_ENV === 'production') opts.domain = '.geniam.com';
      localStorage.setItem('accessToken', token);
      Cookies.set(GENIAM_REFRESH_TOKEN, refreshToken, opts);
      localStorage.setItem('refreshToken', refreshToken);

      delete query.token;
      delete query.refreshToken;
      history.push(`${path}?${qs.stringify(query)}`)
    }
    getUserData().then(res => {
      OldBuyCourse();
      getSubscriptions();
      getCoins()
    }).catch(e => {
      console.log(e);
    });

    auth.onAuthStateChanged(user => {
      if (!user) {
        signInFirebase()
      }
    });

    // eslint-disable-next-line
  }, []);

  // useFetchData()

  if (loading)
    return <ClfLinearProgress/>

  return (
    <div>
      <MenuAppBar/>
      <div className="App marTop">
        <Switch>
          <Route exact path={'/'} component={HomePage}/>
          <StreamProviderContext>
            <Route exact path={'/school'} component={LiveStreaming}/>
          </StreamProviderContext>
          <Route exact path={'/history'} component={HistoryComponent}/>
          <Route exact path={'/mtview'} component={MetamorItems}/>
          <Route component={NotFound}/>
        </Switch>
        <Cart/>
        <ToastContainer
          hideProgressBar={true}
          // closeOnClick={false}
          autoClose={1500}
        />
      </div>
    </div>
  );
}

function NotFound() {
  return (
    <div>
      Not Found
    </div>
  );
}

export default withRouter(App);
