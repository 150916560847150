import React, {useState} from 'react';
import DialogCustom from "../../Custom/DialogCustom";
import {Col, Row} from "antd";
import IconButtonCustom from "../../Custom/IconCustom";
import {makeStyles} from "@material-ui/core/styles";
import {useGlobal} from "reactn";
import TextFieldAloneCustom from "../../Custom/TextFieldAloneCustom";
import {CLFButtonSVG} from "react-antd-button-svg-icons";
import {v4 as uuid} from 'uuid';
import moment from "moment";
import {useStreamContext} from "../../../context/StreamProviderContext";
import {getRoomsDocRef} from "../../../common/useRef";

function DialogAddRoom({open, onClose, idLive, index = 0}) {
  const classes = useStyles();
  const {iFrame} = useStreamContext();
  const [, setOpenAddTask] = useGlobal('openAddTask')
  const [user] = useGlobal('user')
  const [item, setItem] = useState({
    title: '',
    description: '',
    conferenceUrl: '',
    taskIcon: {},
    creator: {},
    isDeleted: false,
  })
  const [loading, setLoading] = useState(false)

  const onChange = (value, field) => {
    setItem({...item, [field]: value})
    if (field === 'taskIcon')
      setOpenAddTask(null)
  }

  const onSave = async () => {
    if (!user?.user_id)
      return;
    setLoading(true)
    try {
      const roomId = uuid();
      const roomValue = {
        ...item,
        creator: {
          userId: user.user_id,
          email: user.email,
          name: user.nickname || '',
        },
        createdAt: moment().format(),
        updatedAt: moment().format(),
        isDeleted: false,
        idEmbed: iFrame.embed.id
      }
      const roomDocRef = getRoomsDocRef(idLive, roomId)
      await roomDocRef.set(roomValue);

    } catch (e) {
      console.log(e.toString())
    } finally {
      setLoading(false)
      onClose()
    }
  }

  return (
    <DialogCustom
      fullWidth
      maxWidth={'sm'}
      title={'Add new room'}
      open={open}
      onClose={onClose}
      actions={<React.Fragment>
        <CLFButtonSVG
          name={'Cancel'}
          onClick={onClose}
          minWidth={'100px'}
          className={classes.secondary}
        />
        <CLFButtonSVG
          minWidth={'100px'}
          name={'Ok'}
          onClick={onSave}
          loading={loading}
          disable={loading}
          className={classes.primary}
        />
      </React.Fragment>}
    >
      <Row gutter={16}>
        <Col xs={4} className={classes.iconLeft}>
          <IconButtonCustom
            room={item}
            idLive={idLive}
            setOpenAddTask={setOpenAddTask}
            index={index}
            callback={onChange}
            field={'taskIcon'}
          />
        </Col>
        <Col xs={20} className={classes.roomInfo}>
          <div className={classes.textField}><span>Title</span>
            <TextFieldAloneCustom value={item.title} callback={onChange}
                                  field={'title'}/></div>
          <div className={classes.textField}><span>Descriptions</span>
            <TextFieldAloneCustom value={item.description} callback={onChange}
                                  field={'description'}/></div>
          <div className={classes.textField}><span>Conference Url</span>
            <TextFieldAloneCustom value={item.conferenceUrl} callback={onChange}
                                  field={'conferenceUrl'}/></div>
        </Col>
      </Row>
    </DialogCustom>
  );
}


const useStyles = makeStyles(theme => ({
  root: {
    background: '#F1F1F1',
    borderRadius: 5,
    position: 'relative'
  },
  textField: {
    width: '100%',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    '& span': {
      margin: "20px 10px 0px 0px",
      minWidth: 120
    }
  },
  roomInfo: {
    padding: 3,
    fontSize: '1rem'
  },
  divPosition: {
    position: 'relative'
  },
  iconLeft: {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center'
  },
  iconTask: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    //
    // position: 'absolute',
    // top: '50%',
    // right: 10,
    // transform: ' translateY(-65%)'
  },
  addIconSVGTask: {
    padding: 10,
    width: 28,
    height: 28,
    marginTop: 2,
    background: 'transparent !important',
    border: '1px solid #F1F1F1',
    "& svg": {
      fill: theme.props.primaryColor,
    }
  },
  timeRound: {
    fontSize: 20
  },
  confirmStyle: {
    zIndex: '9999 !important'
  },
  primary: {
    background: `#1790FF !important`,
    borderColor: `#1790FF !important`
  },
  secondary: {
    background: `#690003 !important`,
    borderColor: `#690003 !important`
  },
}));

export default DialogAddRoom;
