import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from "@material-ui/core/Fab";
import { setGlobal } from 'reactn'

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    // maxWidth: "965px",
    height: "100px",
    margin: "auto",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    [theme.breakpoints.down('xs')]: {
      right: 0
    },
    display: "grid",
    zIndex: 1,
  },
  DivAbout: {
    backgroundColor: "black",
    width: "100%",
    height: "100%",
    opacity: 0.5,
    position: "absolute",
  },
  DivButton: {
    margin: "auto",
  },
  fab: {
    margin: "auto",
    backgroundColor: "#8CC63F",
    height: 50,
    width:300,
    borderRadius: 0,
    fontWeight: "bold",
    color: "white"

  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },

}));

function LoadingFixFooter(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.DivAbout}></div>
      <div className={classes.DivButton} onClick={() => {
        setGlobal({showCart: true})
      }}>
        <Fab variant="extended" aria-label="Delete" className={classes.fab}>
          <img style={{width: 35, height: 35}} src={'image/checkked.png'} className={classes.extendedIcon}
               alt={"icon checked"}/>
          したワークショップに申し込む
        </Fab>
      </div>
    </div>
  );
}

export default LoadingFixFooter;
