import React, {useEffect} from 'react';
import {useGlobal} from 'reactn'
import _ from 'lodash'
import HoverMetamors from "./HoverMetamors";

function Metamors() {
  const [metamors, setMetamors] = useGlobal('metamor')
  const [schedules] = useGlobal('schedules')

  useEffect(() => {
    let arr = schedules.map(schedule => {
      return {...schedule.metamor, checked: true}
    })
    setMetamors(_.uniqBy(arr, 'uuid'))

    // eslint-disable-next-line
  }, [schedules])

  return (
    <div>
      <h3 style={{
        fontZize: "26pt",
        margin: 0,
        lineHeight: "50px",
      }}>提供者で選ぶ</h3>
      {metamors.map((metamor, index) => <HoverMetamors metamor={metamor}
                                                       key={index}
                                                       index={index}/>)}
    </div>
  )
}


export default Metamors;
