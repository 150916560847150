import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import ListRoom from "../ListRoom";
import {ReactComponent as AddLargeSVG} from "../../asSvg/icons/addLarge.svg";
import styled from "styled-components";
import {Button} from "antd";
import DialogAddRoom from "../RoomDialog/DialogAddRoom";
import {useStreamContext} from "../../../context/StreamProviderContext";
import {useGlobal} from "reactn";


const IconButton = styled(Button)`
  margin-right: 5px;
  margin-bottom: 5px;
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  box-shadow: none !important;
  height: 40px !important;

  & span {
    font-size: 25px;
  }

  &:hover {
    background-color: #f1f1f1;
    color: unset
  }

  &:focus {
    background-color: #f1f1f1;
    color: unset
  }
`


function UserAddInfo(props) {
  const classes = useStyles();
  const [user] = useGlobal('user')
  const {iFrame} = useStreamContext();
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.divList}>
      <div className={classes.root}>
        <ListRoom/>
        {
          user?.user_id &&
          <IconButton
            disableRipple={true}
            className={classes.addIconSVGTask}
            onClick={() => setOpen(true)}
          >
            <AddLargeSVG/>
          </IconButton>
        }
      </div>
      {
        open &&
        <DialogAddRoom idLive={iFrame.id} onClose={onClose} open={open}/>
      }
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    background: '#FFFFFF',
    borderRadius: '5px',
    padding: '5px',
    maxHeight: "550px",
    overflowY: 'auto',
  },
  divList: {
    position: "relative"
  },
  addIconSVGTask: {
    position: 'absolute',
    bottom: 3,
    left: -3,
    margin: "auto",
    justifyContent: 'center',
    marginTop: 2,
    background: 'transparent !important',
    border: '1px solid #F1F1F1',
    "& svg": {
      fill: theme.props.primaryColor,
    }
  }
}))
export default UserAddInfo;
