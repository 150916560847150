import {useEffect, useState} from 'react';
import {
  get,
  includes,
  isFunction,
  isString,
  pick,
  pickBy,
  reduce,
  sortBy
} from 'lodash';

const dfn = () => {
  return true;
}

export default function useHotKey(keys = [], domQuerySelector, runFn) {

  const [isPressed, setIsPressed] = useState(false)

  const _keys = (isString(keys) ? [keys] : keys).map(k => rerange(k));
  let _fn
  if (isString(domQuerySelector)) {
    _fn = (evt) => hasEl(evt, domQuerySelector)
  } else {
    _fn = _fn || dfn;
  }

  useEffect(() => {
    document.addEventListener('keydown', handlePress);
    return () => {
      document.removeEventListener('keydown', handlePress)
    }
  }, [])

  const handlePress = evt => {
    evt = evt || window.event;
    if (!evt)
      return;
    const pressedObj = pick(evt, ['altKey', 'ctrlKey', 'metaKey', 'shiftKey', 'key']);
    const keysString = rerange(getPressedKeysString(pressedObj, pressedObj.key))//ex: meta+shift+enter
    const _isPressed = includes(_keys, keysString) && _fn(evt)
    if (_isPressed && isFunction(runFn))
      runFn()
    // console.log({_isPressed}, keysString, getId(evt), evt)
    setIsPressed(_isPressed)
  }

  return [isPressed, setIsPressed]
}

const useCtrlWithKey = (key, domQuerySelector, runFn) => {
  return useHotKey([`ctrl+${key}`, `meta+${key}`], domQuerySelector, runFn)
}
const useShiftWithKey = (key, domQuerySelector, runFn) => {
  return useHotKey(`shift+${key}`, domQuerySelector, runFn)
}

const useCtrlShiftWithKey = (key, domQuerySelector, runFn) => {
  return useCtrlWithKey(`shift+${key}`, domQuerySelector, runFn)
}
const useCtrlEnter = (domQuerySelector, runFn) => {
  return useCtrlWithKey('enter', domQuerySelector, runFn)
}
const useEnter = (domQuerySelector, runFn) => {
  return useHotKey('enter', domQuerySelector, runFn)
}
const useBackSpace = (domQuerySelector, runFn) => {
  return useHotKey(['backspace', 'delete'], domQuerySelector, runFn)
}

export {
  useCtrlWithKey,
  useShiftWithKey,
  useCtrlShiftWithKey,
  useCtrlEnter,
  useBackSpace,
  useEnter
}
//helpers
const getPressedKeysString = (obj, mainKey) => {
  const specicalKeyString = reduce(
    pickBy(obj),
    (res, v, k) => {
      if (`${k}`.includes('Key'))
        res += `${k}`.replace('Key', '') + "+"
      return res;
    }, '')
  return specicalKeyString + mainKey
}
const rerange = (str) => {
  return sortBy(
    `${str}`
      .toLowerCase()
      .replace(/ /g, '')
      .split('+')
  ).join('+')
}
const getId = evt => get(evt, 'target.id') || get(evt, 'target.firstChild.id')
const hasEl = (evt, domQuerySelector) => !!document.activeElement.parentElement.querySelector(domQuerySelector)
