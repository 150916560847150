import React, {useEffect, useState} from 'react';
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import {useEnter} from "../../common/useHotkey";
import {isEmpty, trim} from "lodash";

function TextFieldAloneCustom({value = '', callback, field}) {
  const classes = useStyles();
  const [title, setTitle] = useState('')

  useEffect(() => {
    if (value)
      setTitle(value);
  }, [value])

  const onChange = (e) => {
    console.log(e);
    setTitle(e.target.value)
  }

  const [isTitleInputEnter] = useEnter(`#title-input-${field}`)

  useEffect(() => {
    if (isTitleInputEnter) onSave()
  }, [isTitleInputEnter])

  const onSave = () => {
    if (isEmpty(trim(title))) {
      setTitle(value)
      return;
    }
    if (title === value) {
      return;
    }
    callback(title, field)
  }

  return (
    <TextField
      value={title}
      variant="outlined"
      id={`title-input-${field}`}
      className={classes.textField}
      onChange={(e) => onChange(e)}
      onBlur={onSave}
      autoFocus={field === 'title'}
    />
  );
}

const useStyles = makeStyles(theme => ({
  textField: {
    width: "100%",
    borderBottom: `1px solid ${theme.props.primaryColor}`,
    textAlign: "center",
    maxWidth: 400,

    "& .MuiOutlinedInput-root": {
      // border: `1px solid ${theme.props.primaryColor}`,
      border: "none",
      borderRadius: '24px',
      textAlign: "center",
      fontSize: '1rem',
      fontWeight: "bold",
      color: theme.props.primaryColor,
      [theme.breakpoints.down('md')]: {
        fontSize: 14,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
    "& .MuiOutlinedInput-root fieldset": {
      border: theme.props.primaryColor,
    },
    "& .MuiOutlinedInput-input": {
      padding: 5,
      // fontSize: 18,
    }
  },
}))

export default TextFieldAloneCustom;
