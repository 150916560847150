import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useStreamContext} from "../../../context/StreamProviderContext";
import ItemList from "../../Custom/ItemList";
import {getRoomsDocRef} from "../../../common/useRef";
import SelectIconsDialog from "../../SelectIcons/SelectIconsDialog";
import {useGlobal} from "reactn";

function ListRoom() {
  const classes = useStyles();
  const {listRoom, iFrame} = useStreamContext();
  const [loading, setLoading] = useState(false);
  const [openAddTask, setOpenAddTask] = useGlobal('openAddTask')

  const handleAdd = async ({
                             icon = '',
                             name = null,
                             type = '',
                             file = null
                           }) => {

    if (loading || !type || (type === "file" && !file) || (type === "icon" && !icon))
      return
    setLoading(true)
    try {
      const newIcon = {
        icon,
        name,
        file,
        type
      }
      if (type === 'file')
        newIcon.icon = file.id

      const idLive = openAddTask.idLive
      const roomId = openAddTask.id
      const roomRef = getRoomsDocRef(idLive, roomId)

      await roomRef.set({taskIcon: newIcon}, {merge: true})
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false)
      setOpenAddTask(null)
    }
  }

  if (!iFrame?.id)
    return null;

  return (
    <div className={classes.root}>
      {
        listRoom?.length ?
          listRoom.map((room, index) => {
            return <ItemList room={room} idLive={iFrame.id} index={index}/>
          })
          :
          <div className={classes.noRoom}>No room available</div>
      }
      {
        Boolean(openAddTask) &&
        <SelectIconsDialog onAdd={handleAdd} loading={loading}/>
      }
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10
  },
  noRoom: {
    width: '100%',
    textAlign: 'center',
    color: "red"
  }
}))
export default ListRoom;
