export const giftData = [
  {
    id: 1,
    src: "/image/gift/img.png",
    price: 10,
  },
  {
    id: 2,
    src: "/image/gift/img_1.png",
    price: 10,
  },
  {
    id: 3,
    src: "/image/gift/img_2.png",
    price: 10,
  },
  {
    id: 4,
    src: "/image/gift/img_3.png",
    price: 10,
  },
  {
    id: 5,
    src: "/image/gift/img_4.png",
    price: 10,
  },
  {
    id: 6,
    src: "/image/gift/img_5.png",
    price: 50,
  },
  {
    id: 7,
    src: "/image/gift/img_6.png",
    price: 50,
  },
  {
    id: 8,
    src: "/image/gift/img_7.png",
    price: 100,
  },
  {
    id: 9,
    src: "/image/gift/img_8.png",
    price: 100,
  },
  {
    id: 10,
    src: "/image/gift/img_9.png",
    price: 200,
  },
  {
    id: 11,
    src: "/image/gift/img_10.png",
    price: 1000,
  },
]
