import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {InputAdornment, TextField, Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {toast} from "react-toastify";
import {useStreamContext} from "../../context/StreamProviderContext";
import {getRoomsDocRef} from "../../common/useRef";
import {useEnter} from "../../common/useHotkey";
import {isEmpty, trim} from "lodash";
import {useGlobal} from "reactn";

const copy = require('clipboard-copy');

function InputLinks({value, room}) {
  const [user] = useGlobal('user')
  const classes = useStyles();
  const {iFrame} = useStreamContext();
  const [url, setUrl] = useState('')

  useEffect(() => {
    if (value)
      setUrl(value)
  }, [value])

  const handleCopy = async () => {
    try {
      if (!value)
        return null;
      const link = value || '';
      await copy(link)
      toast.success("copied")
    } catch (e) {
      console.log(e.toString())
    }
  };

  const [isTitleInputEnter] = useEnter(`#title-input-${value}`)


  useEffect(() => {
    if (isTitleInputEnter) onBlue()
  }, [isTitleInputEnter])

  const handleDelete = async () => {
    try {
      const roomDocRef = getRoomsDocRef(iFrame.id, room.id)
      await roomDocRef.set({conferenceUrl: null}, {merge: true})
      setUrl('')
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpen = () => {
    window.open(value)
  };

  const onChange = (e) => {
    setUrl(e.target.value)
  }

  const onBlue = async () => {
    if (isEmpty(trim(url))) {
      setUrl(value)
      return;
    }
    if (url === value) {
      return;
    }
    try {
      const roomDocRef = getRoomsDocRef(iFrame.id, room.id)
      await roomDocRef.set({conferenceUrl: url}, {merge: true})
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <TextField
      className={classes.copyField}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <>
            <InputAdornment position="start">
              <Tooltip title={'Copy link'}>
                <IconButton onClick={handleCopy}> <FileCopyIcon
                  fontSize={"small"}/></IconButton>
              </Tooltip>
            </InputAdornment>
            <InputAdornment position="start">
              <Tooltip title={'Open conference url'}>
                <VideocamOutlinedIcon
                  className={classes.zoomJoin}
                  onClick={handleOpen}
                  fontSize={'small'}
                />
              </Tooltip>
            </InputAdornment>
            <InputAdornment position="start">
              <Tooltip title={'Remove conference url'}>
                <DeleteOutlineIcon
                  className={classes.zoomJoin}
                  onClick={handleDelete}
                  fontSize={'small'}
                />
              </Tooltip>
            </InputAdornment>
          </>
        ),
      }}
      label={'Conference url'}
      InputLabelProps={{shrink: true}}
      value={url ? url : ''}
      onChange={onChange}
      onBlur={onBlue}
      // disabled={disabled}
    />
  );
}

const useStyles = makeStyles(theme => ({
  copyField: {
    width: "98%",
    margin: "10px 0px",
    // "& ."
    "&& .MuiOutlinedInput- root": {
      borderRadius: 0
    },
    "&& .MuiOutlinedInput-adornedStart": {
      paddingLeft: 0
    },
    "&& .MuiOutlinedInput-adornedEnd": {
      height: "40px",
      padding: 0
    },
    "&& .MuiOutlinedInput-notchedOutline": {
      border: '1px solid black'
    },
    "& .MuiInputAdornment-positionStart": {
      margin: 0
    }
  },
  zoomJoin: {
    marginRight: 10,
    cursor: 'pointer'
  },

}))

export default InputLinks;
