import axios from "axios";
import {API_GENIAM} from "../config/apiUrl";


export default async function () {
  try {
    const {data} = await axios.get(`${API_GENIAM}/v1/cart/payment/cards`)
    return data
  } catch (e) {
    console.log(e);
    return []
  }
}
