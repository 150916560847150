import React, {useEffect} from 'react';
import styled, {css} from 'styled-components';
import {BorderButton} from "../common/BorderButton";
import {isMobileOnly} from 'react-device-detect'
import axios from 'axios'
import {useGlobal} from 'reactn'
import {API_PREFIX} from "../../config/apiUrl";

const Button = styled.button`
    width: 23%;
    margin: 0 1% 30px 1%;
    color: #fff;
    background: #000;
    position: relative;
    border: none;
    font-size: 28pt;
    padding: 4px 0;
    outline: none !important;
    cursor: pointer;
    padding: 0;
    ${props => props.isMobile && css`
        font-size: 10pt;
        padding: 5px 0;
        margin: 0 1% 15px 1%;
    `}
`;


const Title = styled.h3`
    font-size: 26pt;
    margin: 0;
    ${props => props.isMobile && css`
        font-size: 18pt;

    `}
`;
function Category() {

    const [categories, setCategories] = useGlobal('categories')
    useEffect(() => {
        axios.get(API_PREFIX + '/questum/categories')
            .then(res => {
                if (res.data) {
                    let arr = []
                    res.data.forEach(category => {
                        arr.push({...category, checked: true})
                    })
                    setCategories(arr)
                }
            }).catch(e => {
            console.log(e)
        })
      // eslint-disable-next-line
    }, [])
    const toggleCategory = (index) => {
        categories[index] = {...categories[index], checked: !categories[index].checked}
        setCategories([...categories])
    }
    return (
        <div>
            <Title isMobile={isMobileOnly}>カテゴリ</Title>
            {categories.map((category, index) => {
                return (
                    <Button key={category.id} onClick={() => toggleCategory(index)}
                            isMobile={isMobileOnly}>{category.name}
                        <BorderButton isMobile ={isMobileOnly} checked={category.checked}/>
                    </Button>
                )
            })}

        </div>
    )
}

export default Category;
