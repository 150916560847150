import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Col, Row} from "antd";
import {useStreamContext} from "../../context/StreamProviderContext";
import UserAddInfo from "./UserAddInfo";
import Gift from "./Gift";

function LiveStreaming(props) {
  const classes = useStyles();
  const {iFrame} = useStreamContext();
  return (
    <div className={classes.root}>
      <Row gutter={16} className={classes.streaming}>
        <Col sm={24} md={16} lg={18}>
          <div
            dangerouslySetInnerHTML={{__html: iFrame?.embed?.video || ''}}/>
        </Col>
        <Col sm={24} md={8} lg={6}>
          <UserAddInfo/>
          {/* hidden chat from vimeo */}
          {/*<div*/}
          {/*  className={classes.chatFrame}*/}
          {/*  dangerouslySetInnerHTML={{__html: iFrameChat}}*/}
          {/*/>*/}
          <Gift data={iFrame}/>
        </Col>
      </Row>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    background: "#F1F1F1",
    width: "100%",
    height: "100%",
    overflow: "hidden"
  },
  streaming: {
    padding: "20px"
  },
  divLive: {
    padding: '56.25% 0 0 0',
    position: 'relative'
  },
  chatFrame: {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
  }
}))
export default LiveStreaming;
